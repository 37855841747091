import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import BackgroundImage from 'gatsby-background-image'

const AgenciaDesign = () => (
  <StaticQuery query = { graphql `
      query {
        desktop: file(relativePath: { eq: "agencia-design.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    ` }
  render = {
    data => {
      const imageData = data.desktop.childImageSharp.fluid
      return ( <BackgroundImage Tag = "section"
            fluid = { imageData }
            className="agencia-design"
            backgroundColor = { `#FFF` } >  
          </BackgroundImage>
      )
    }
  }
  />
)

export default AgenciaDesign