import React from "react"

import AgenciaScrum from "./agencia-scrum"
import AgenciaDesarrollo from "./agencia-desarrollo"
import AgenciaDesign from "./agencia-design"
import AgenciaProducto from "./agencia-producto"

const ComoLoHacemosAgencia = () => (
  <div className="container padding-0">
    <div className="container-warped-agencia">
      <div className="container-warped-agencia__item">
        <div className="container-warped-agencia__image">
          <AgenciaScrum />
        </div>
        
        <div className="container-warped-agencia__text">
          <span>Utilizamos metodologías ágiles: </span>
          <span className="bold">SCRUM y LEAN</span>
        </div>
      </div>
      <div className="container-warped-agencia__item">
        <div className="container-warped-agencia__image">
          <AgenciaDesarrollo />
        </div>
        <div className="container-warped-agencia__text">
          <span>Aplicamos estrategias de </span>
          <span className="bold">desarrollo iterativo</span>
        </div>
      </div>
      <div className="container-warped-agencia__item">
        <div className="container-warped-agencia__image">
          <AgenciaDesign />
        </div>
        <div className="container-warped-agencia__text">    
          <span>Construimos soluciones innovadoras por medio de </span>
          <span className="bold">Design Thinking</span>
        </div>
      </div>
      <div className="container-warped-agencia__item">
        <div className="container-warped-agencia__image">
        <AgenciaProducto />
        </div>
        <div className="container-warped-agencia__text">
          <span>Producto mínimo viable, pruebas de concepto y prototipos</span>
        </div>
      </div>
    </div>
    
  </div>
)

export default ComoLoHacemosAgencia