import React from "react"

import ComoLoHacemosAgencia from "../components/agencia/como-lo-hacemos-agencia"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TitleH2 from "../components/globals/title-h2"

import video from "../videos/video-background-agencia.mp4"
import posteragencia from "../images/poster-agencia.jpg"

import AgenciaMoviles from "../components/agencia/agencia-moviles"
import AgenciaWeb from "../components/agencia/agencia-web"
import AgenciaEmpresariales from "../components/agencia/agencia-empresariales"
import AgenciaProfesionales from "../components/agencia/agencia-profesionales"
import AgenciaFooter from "../components/agencia/agencia-footer"

import "../scss/agencia-styles.scss"

const AgenciadeSoftware = () => (
  <Layout footer={true} menuactive="agencia">
    <SEO title="Agencia de software" />
    <div className="video-background-container">
      <h1 className="message bold">
        Desarrollo de software
      </h1>
      <span className="subtitle">
        Aplicaciones móviles, aplicaciones web, sistemas<br></br>
        empresariales y servicios profesionales
      </span>
      <video autoPlay loop muted poster={posteragencia}>
        <source src={video} type="video/mp4" />
      </video>
    </div>
    <div className="container padding-0">
      <div className="container-warped">
        <TitleH2 titleName="¿Qué hacemos?" align="left"></TitleH2>
        <p className="txt-description">
          Creamos software para atender los retos presentes y futuros de tu organización y del mercado con el objetivo de incrementar el valor de tu negocio, ofreciendo una gran experiencia de usuario que crea fidelidad y constante interacción con tu marca.
        </p>
      </div>
    </div>
    <div className="row bkg-gray">
      <div className="col txt-container">
        <h3 className="h3--black__2 padding-container"><span className="light">Aplicaciones</span> móviles</h3>
        <div className="txt-description padding-container">
          <ul className="list-1">
            <li>Nativas e híbridas</li>
            <li>Progressive Web Apps</li>
            <li>iOS y Android</li>
            <li>Smartphone, Tablet y Smartwatch</li>
            <li>Apple TV y Android TV</li>
          </ul>
        </div>
      </div>
      <div className="col image__responsive">
        <AgenciaMoviles />
      </div>
    </div>
    <div className="row bkg-gray row__inverse">
      <div className="col image__responsive">
        <AgenciaWeb />
      </div>
      <div className="col txt-container">
        <h3 className="h3--black padding-container"><span className="light">Aplicaciones</span> web</h3>
        <div className="txt-description padding-container">
        <ul className="list-1">
          <li>Diseño y desarrollo web <br />(Mobile First, Responsive, y Adaptive)</li>
          <li>Posicionamiento en buscadores</li>
        </ul>
        </div>
      </div>
    </div>
    <div className="row bkg-gray">
      <div className="col txt-container">
        <h3 className="h3--black__2 padding-container">Sistemas <span className="light">empresariales</span></h3>
        <div className="txt-description padding-container">
        <ul className="list-1">
          <li>E-Commerce</li>
          <li>Aplicaciones bancarias</li>
          <li>Cotizadores financieros</li>
          <li>Sistemas para Back-office</li>
        </ul>
        </div>
      </div>
      <div className="col image__responsive">
        <AgenciaEmpresariales />
      </div>
    </div>
    <div className="row bkg-gray row__inverse">
      <div className="col image__responsive">
        <AgenciaProfesionales />
      </div>
      <div className="col txt-container">
        <h3 className="h3--black padding-container">Servicios <span className="light">profesionales</span></h3>
        <div className="txt-description padding-container">
        <ul className="list-1">
          <li>Modernización de proyectos de software</li>
          <li>Mantenimiento de software desarrollado por terceros</li>
          <li>Levantamiento de requerimientos</li>
          <li>Diseño de UX/UI</li>
          <li>Fábrica de pruebas</li>  
        </ul>
        </div>
      </div>
    </div>
    <TitleH2 titleName="¿Cómo lo hacemos?" align="center bkg-white"></TitleH2>
    <ComoLoHacemosAgencia />
    <div className="container margin-0 padding-bottom-0">
      <div className="container-warped margin-0 bkg-gray">
        <TitleH2 titleName="Nuestra experiencia" align="left"></TitleH2>
        <p className="txt-description">
          Nuestro equipo cuenta con más de 20 años de experiencia en el desarrollo de software para:
        </p>
        <div className="container-list-1">
          <ul className="list-1">
            <li>Banca y Servicios Financieros</li>
            <li>Retail, E-commerce y Manufactura</li>
            <li>Medios y Entretenimiento</li>
            <li>Alta tecnología y Comunicaciones</li>
            <li>Energía, Recursos e Infraestructura</li>
            <li>Salud y Bienestar</li>
            <li>Gobierno y Servicios Públicos</li>  
          </ul>
        </div>
      </div>
    </div>
    <div className="container padding-0 bkg-blue footer-padding">
      <div className="row">
        <div className="col">
          <div className="footer-agencia__text">
          <TitleH2 titleName="Valor agregado" align="left text-white"></TitleH2> 
          <ul className="list-2">
          <li>Somos ágiles en la construcción de software</li>
          <li>Trabajamos en ciclos cortos de desarrollo</li>
          <li>Valoramos la experiencia del usuario</li>
          <li>Entregamos funcionalidad frecuente</li>
          <li>Favorecemos la cooperación y la comunicación</li>
          <li>Tenemos apertura al cambio</li>
          <li>Enfatizamos la calidad</li>  
        </ul>
          </div>
        </div>
        <div className="col">
          <div className="footer-agencia__image">
            <AgenciaFooter />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default AgenciadeSoftware